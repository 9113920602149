<template>
    <div>
      <CCard>
        <CCardHeader class="d-flex align-items-center justify-content-between">
          {{ $t('evaluationTitle') }}
					<div class="d-flex align-items-center"
						v-if="$store.state.userData && evaluationData && $store.state.userData.id_usuario != evaluationData.evaluado.id_usuario">
						Enviar Mensaje a usuario
						<CButton size="sm" color="success" class="mx-1" @click="chatWithUser()" :disabled="loadingNewChat">
							<CIcon name="cil-chat-bubble" size="sm"/>
						</CButton>
					</div>
        </CCardHeader>
        <CCardBody>
          <div class="my-5 d-flex flex-column align-items-center justify-content-center" v-if="loadingEvaluation || !evaluationData">
            <div class="spinner-border text-primary mb-3" style="width: 70px; height: 70px;" role="status">
            </div>
            <p class="mb-0 text-primary">{{ $t('loadingEvaluation') }}</p>
          </div>
          <CTabs variant="pills" :vertical="{ navs: 'col-sm-3', content: 'col-sm-9' }" v-else>
            <CTab active>
              <template slot="title">
                <CIcon name="cil-user" class="mr-2"/> {{ $t('evaluatedUserData') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2"> {{ $t('evaluatedUserData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('name') }}: </b> {{evaluationData.evaluado.nombre}} {{evaluationData.evaluado.apellido}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('gender') }}: </b> {{evaluationData.evaluado.sexo}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('email') }}: </b> {{evaluationData.evaluado.email}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('phone') }}: </b> {{evaluationData.evaluado.telefono}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('region') }}: </b> {{evaluationData.evaluado.region}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('comuna') }}: </b> {{evaluationData.evaluado.comuna}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('address') }}: </b> {{evaluationData.evaluado.direccion}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('birth') }}: </b> {{evaluationData.evaluado.nacimiento}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('age') }}: </b> {{evaluationData.evaluado.nacimiento | age}} {{ $t('years') }}
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-4" v-if="evaluationData.clinical_data">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('evaluatedUserClinicalData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('temperature') }}: </b> {{evaluationData.clinical_data.temperatura}} ºC
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('dificulToBreath') }}: </b> {{evaluationData.clinical_data.dificultad_respirar ? $t('yes'):$t('no') }}
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-4 mb-4" v-if="evaluationData.clinical_data">
                <CCol xs="12" lg="6">
                  <div v-if="evaluationData.clinical_data.ecnt">
                    <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                      <b>{{ $t('entDisease') }}</b>
                    </p>
                    <p class="text-center text-primary mb-1 d-flex align-items-center justify-content-center px-2 py-1"
                      v-if="evaluationData.clinical_data.ecnt.every((item) => { return item.value != true })">
                      <b>{{ $t('noEntDisease') }}</b>
                    </p>
                    <div v-else>
                      <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1"
                        :class="index%2 != 0 ? 'bg-secondary':''"
                        v-for="(ent, index) in evaluationData.clinical_data.ecnt.filter((item) => {return item.value == true})"
                        :key="index">
                        <b class="text-primary">{{ $t(ent.name) }}: </b> {{ent.tratamiento ? $t('inTreatment'):$t('noTreatment') }}
                      </p>
                    </div>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div v-if="evaluationData.clinical_data.autoinmune">
                    <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                      <b>{{ $t('autoinmuneDisease') }}</b>
                    </p>
                    <p class="text-center text-primary mb-1 d-flex align-items-center justify-content-center px-2 py-1"
                      v-if="evaluationData.clinical_data.autoinmune.every((item) => { return item.value != true })">
                      <b>{{ $t('noAutoinmuneDisease') }}</b>
                    </p>
                    <div v-else>
                      <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1"
                        :class="index%2 != 0 ? 'bg-secondary':''"
                        v-for="(ent, index) in evaluationData.clinical_data.autoinmune.filter((item) => {return item.value == true})"
                        :key="index">
                        <b class="text-primary">{{ $t(ent.name) }}: </b> {{ent.tratamiento ? $t('inTreatment'):$t('noTreatment') }}
                      </p>
                    </div>
                  </div>
                </CCol>
                <CCol xs="12" lg="12" class="mt-3">
                  <div v-if="evaluationData.clinical_data.exams">
                    <p class="text-center text-white mb-1 d-flex align-items-center justify-content-center px-2 py-1 bg-dark">
                      <b>{{ $t('examsTitle') }}</b>
                    </p>
                    <p class="text-center text-primary mb-1 d-flex align-items-center justify-content-center px-2 py-1"
                      v-if="evaluationData.clinical_data.exams.every((item) => { return item.status != true })">
                      <b>{{ $t('noAutoinmuneDisease') }}</b>
                    </p>
                    <div v-else>
											<CCol sm="12" md="12">
												<CRow class="bg-dark no-gutters">
													<CCol sm="12" md="6">
															<p class="mb-0 text-white px-2">{{$t('exam')}}</p>
													</CCol>
													<CCol sm="12" md="2">
															<p class="mb-0 text-white text-center px-2">{{$t('value')}}</p>
													</CCol>
													<CCol sm="12" md="2">
															<p class="mb-0 text-white text-center px-2">{{$t('measureUnit')}}</p>
													</CCol>
													<CCol sm="12" md="2">
															<p class="mb-0 text-white text-center px-2">{{$t('limitValue')}}</p>
													</CCol>
												</CRow>
												<CRow class="no-gutters px-1 py-1" align-vertical="center"
														v-for="(exam, index) in evaluationData.clinical_data.exams.filter((item) => {return item.status == true})"
														:key="index"
														:class="index%2 != 0 ? 'bg-secondary':''">
													<CCol sm="12" md="6">
														<p class="mb-0 d-flex align-items-center justify-content-between px-2 py-1">
															{{exam.name}}
														</p>
													</CCol>
													<CCol sm="12" md="2">
														<p class="mb-0 text-center text-primary font-weight-bold">
															{{exam.value}}
														</p>
													</CCol>
													<CCol sm="12" md="2">
															<p class="mb-0 px-2 text-center ">{{exam.unit ? exam.unit:''}}</p>
													</CCol>
													<CCol sm="12" md="2">
															<p class="mb-0 px-2 text-center ">{{exam.limit ? exam.limit:''}}</p>
													</CCol>
												</CRow>
											</CCol>
                    </div>
                  </div>
                </CCol>
              </CRow>
							<CRow class="mb-3">
								<CCol sm="12" md="12">
									<h5 class="bg-primary text-white text-center py-2">{{$t('userDocumentsTitle')}}</h5>
								</CCol>
							</CRow>
							<CRow class="mx-2 my-1 bg-secondary py-2 rounded-lg" alignVertical="center"
									v-if="evaluationData.evaluado.attachments.length > 0">
								<CCol sm="12" md="4"
									v-for="(file, index) in evaluationData.evaluado.attachments" :key="index" >
									<div class="d-flex flex-row align-items-center">
										<div>
											<p class="mb-0">{{$t('title')}}: {{file.title}}</p>
											<p class="mb-0">{{$t('description')}}: {{file.description}}</p>
											<p class="mb-0">{{$t('created')}}: {{file.created_at}}</p>
											<p class="mb-0">{{$t('createdBy')}}: {{file.uploader.nombre}} {{file.uploader.apellido}} {{file.uploader.especialidad ? '('+file.uploader.especialidad+')':''}}</p>
											<a :href="file.file_path" target="_blank" class="mb-0"><CIcon name="cil-file"/> {{$t('viewFile')}}</a>
										</div>
									</div>
								</CCol>
							</CRow>
							<CRow class="mx-2 my-1 py-2 rounded-lg" alignVertical="center" v-else>
								<CCol sm="12" md="12">
									<div class="d-flex flex-row align-items-center justify-content-center">
										<p class="mb-0">No hay documentos asociados al usuario</p>
									</div>
								</CCol>
							</CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-list"/> {{ $t('entryData') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('basicData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('cronAge') }}: </b> {{evaluationData.evaluacion.ecrono}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('gender') }}: </b> {{evaluationData.evaluado.sexo}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('height') }}: </b> {{evaluationData.evaluacion.estatura}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('weight') }}: </b> {{evaluationData.evaluacion.peso}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('alcohol') }}: </b> {{evaluationData.evaluacion.alcohol}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('smoke') }}: </b> {{evaluationData.evaluacion.fuma}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('neckPerimeter') }}: </b> {{evaluationData.evaluacion.cuello}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('waistPerimeter') }}: </b> {{evaluationData.evaluacion.pcintura}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('hipPerimeter') }}: </b> {{evaluationData.evaluacion.pcadera}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('rightArmPerimeter') }}: </b> {{evaluationData.evaluacion.pbrazo}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('rightLegPerimeter') }}: </b> {{evaluationData.evaluacion.ppierna}} cm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('diastolic') }}: </b> {{evaluationData.evaluacion.presion_d}} mmHg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('sistolic') }}: </b> {{evaluationData.evaluacion.presion_s}} mmHg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary" v-if="evaluationData.evaluacion.triceps">
                      <b class="text-primary">{{ $t('pTriceps') }}: </b> {{evaluationData.evaluacion.triceps}} mm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1" v-if="evaluationData.evaluacion.subescapular">
                      <b class="text-primary">{{ $t('pSubescapular') }}: </b> {{evaluationData.evaluacion.subescapular}} mm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary" v-if="evaluationData.evaluacion.abdominal">
                      <b class="text-primary">{{ $t('pAbdominal') }}: </b> {{evaluationData.evaluacion.abdominal}} mm.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1" v-if="evaluationData.evaluacion.cuadriceps">
                      <b class="text-primary">{{ $t('pCuadriceps') }}: </b> {{evaluationData.evaluacion.cuadriceps}} mm.
                    </p>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('fisiologicalData') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('imc') }}: </b> {{evaluationData.evaluacion.imc | twoDecimal}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('imb') }}: </b> {{evaluationData.evaluacion.imb | twoDecimal}} {{$t('calories')}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('metabolicAge') }}: </b> {{evaluationData.evaluacion.edad_metabolica | twoDecimal}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fatPercent') }}: </b> {{evaluationData.evaluacion.pgrasa | twoDecimal}}%
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('visceralFat') }}: </b> {{evaluationData.evaluacion.rango_grasa_viceral}}
                    </p>
                  </div>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-calculator"/> {{ $t('bodyComposition') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('bodyCompositionAnalysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fatPercent') }}: </b> {{evaluationData.evaluacion.pgrasa | twoDecimal}}%
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('muscularPercent') }}: </b> {{parseFloat(evaluationData.evaluacion.masamuscular) | twoDecimal}}%
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('residualPercent') }}: </b> {{parseFloat(evaluationData.evaluacion.pmresidual) | twoDecimal}}%
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('fatWeight') }}: </b> {{evaluationData.evaluacion.pgraso | twoDecimal}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('muscularWeight') }}: </b> {{parseFloat(evaluationData.evaluacion.pmuscular) | twoDecimal}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('residualWeight') }}: </b> {{parseFloat(evaluationData.evaluacion.mresidual) | twoDecimal}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fullWeight') }}: </b> {{evaluationData.evaluacion.peso | twoDecimal}} Kg.
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('visceralFat') }}: </b> {{evaluationData.evaluacion.rango_grasa_viceral}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('imb') }}: </b> {{evaluationData.evaluacion.imb | twoDecimal}} {{ $t('calories') }}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('imc') }}: </b> {{evaluationData.evaluacion.imc | twoDecimal}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="6">
                  <div>
                    <Doughnut :labels="[$t('graphFat'), $t('graphMuscular'), $t('graphResidual')]"
                      :datasets="[{
                        backgroundColor: [
                          '#41AB35',
                          '#3A2151',
													'#CED2D8',
                        ],
                        data: [evaluationData.evaluacion.pgrasa, parseFloat(evaluationData.evaluacion.masamuscular), parseFloat(evaluationData.evaluacion.pmresidual)]
                      }]"/>
                  </div>
                </CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('waistPerimeter') }} {{ $t('analysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="4">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('waistPerimeter') }} : </b> {{evaluationData.evaluacion.pcintura}} cm.
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="8">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('reference') }}</h5>
									<div>
										<div class="d-flex align-items-center justify-content-center px-2 py-1">
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('acceptable') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{'< ' + evaluationData.evaluacion.pcintideal.a}}</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('aboveNormal') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.pcintideal.a}} - {{evaluationData.evaluacion.pcintideal.c}}</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('risk') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.pcintideal.c}} +</p>
											</div>
										</div>
									</div>
								</CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('fatPercent') }} {{ $t('analysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="4">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('fatPercent') }} : </b> {{evaluationData.evaluacion.pgrasa | twoDecimal}}%
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="8">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('reference') }}</h5>
									<div>
										<div class="d-flex align-items-center justify-content-center px-2 py-1">
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('acceptable') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.grasaidealtabla[0]}}</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('fit') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.grasaidealtabla[1]}}</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('elite') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.grasaidealtabla[2]}}</p>
											</div>
										</div>
									</div>
                </CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('muscularMass') }} {{ $t('analysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="4">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('muscularMass') }} : </b> {{evaluationData.evaluacion.masamuscular}}%
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="8">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('reference') }}</h5>
									<div>
										<div class="d-flex align-items-center justify-content-center px-2 py-1">
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('acceptable') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.muscideal.split(',')[1]}}</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('normal') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.muscideal.split(',')[2]}}</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('fit') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.muscideal.split(',')[3]}}</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('elite') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.muscideal.split(',')[4]}}</p>
											</div>
										</div>
									</div>
								</CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('visceralFat') }} {{ $t('analysis') }}</h5>
                </CCol>
                <CCol xs="12" lg="4">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('visceralFat') }} : </b> {{evaluationData.evaluacion.rango_grasa_viceral}}
                    </p>
                  </div>
                </CCol>
                <CCol xs="12" lg="8">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('reference') }}</h5>
									<div>
										<div class="d-flex align-items-center justify-content-center px-2 py-1">
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('good') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">5 - 7</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('bad') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">8 - 11</p>
											</div>
											<div style="flex: 1">
												<p class="mb-0 text-center"><b>{{ $t('veryBad') }}</b></p>
												<p class="mb-0 text-center" style="flex: 1">12 - 15</p>
											</div>
										</div>
									</div>
								</CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-task"/> {{ $t('training') }}
              </template>
              <CRow class="no-gutters">
                <CCol xs="12" lg="12">
                  <CRow class="mt-3">
                    <CCol xs="12" lg="12">
											<h5 class="bg-primary text-white text-center py-2">{{ $t('metabolicgastoTraining') }}</h5>
											<div class="d-flex align-items-center mb-3">
												<h5 class="py-2 mb-0 mr-3">{{ $t('trainingTime') }}</h5>
												<v-select
													:placeholder="$t('trainingFrequencyQuestion')"
													:options="timeOptions"
													:clearable="false"
													required
													:reduce="data => data.value"
													v-model="selTime"></v-select>
											</div>
                      <div>
                        <div class="d-flex align-items-center justify-content-center bg-dark px-2 py-1 text-center">
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('effortType') }}</b></p>
                          <p class="text-white justify-content-center mb-0 " style="flex: 1"><b>{{ $t('minimum') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('maximum') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('average') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('trainingVolumePercent') }}</b></p>
                          <p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('trainingVolumeMinutes') }}</b></p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center border-bottom text-center">
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ $t('warmUp') }}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.6*0.9*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.6*1.1*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.6*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.scoringNentrenaRef.n1 }}%</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.selTime*this.scoringNentrenaRef.n1/100 }}</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center border-bottom text-center">
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ $t('areobic') }}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.7*0.9*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.7*1.1*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.7*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.scoringNentrenaRef.n2 }}%</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.selTime*this.scoringNentrenaRef.n2/100 }}</p>
                        </div>
                        <div class="d-flex align-items-center justify-content-center border-bottom text-center">
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ $t('increment') }}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.8*0.9*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.8*1.1*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ Math.round( ( 220-parseInt(evaluationData.evaluacion.ecrono) ) *0.8*100 ) / 100}}</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.scoringNentrenaRef.n3 }}%</p>
                          <p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">{{ this.selTime*this.scoringNentrenaRef.n3/100 }}</p>
                        </div>
                      </div>
                    </CCol>
                  </CRow>
                  <CRow class="mt-3">
                    <CCol xs="12" lg="6">
											<!-- <CRow>
												<CCol xs="12" lg="12">
													<h5 class="bg-primary text-white text-center py-2">{{ $t('caloricRequirement') }}</h5>
													<div>
														<div class="d-flex align-items-center justify-content-center px-2 py-1">
															<div style="flex: 1">
																<p class="mb-0 text-center"><b>{{ $t('rcaloricoa') }}</b></p>
																<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.rcalorico * 0.9 | twoDecimal}}</p>
															</div>
															<div style="flex: 1">
																<p class="mb-0 text-center"><b>{{ $t('rcaloricob') }}</b></p>
																<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.rcalorico | twoDecimal}}</p>
															</div>
															<div style="flex: 1">
																<p class="mb-0 text-center"><b>{{ $t('rcaloricoc') }}</b></p>
																<p class="mb-0 text-center" style="flex: 1">{{evaluationData.evaluacion.rcalorico * 1.1 | twoDecimal}}</p>
															</div>
														</div>
													</div>
												</CCol>
											</CRow> -->
											<CRow>
												<CCol xs="12" lg="12">
													<h5 class="bg-primary text-white text-center py-2">{{ $t('scoring') }}: {{evaluationData.evaluacion.scoring | twoDecimal}}</h5>
													<div>
														<div class="d-flex align-items-center justify-content-center bg-dark px-2 py-1">
															<p class="text-white mb-0" style="flex: 1"><b>{{ $t('entryLevel') }}</b></p>
															<p class="text-white mb-0 text-center" style="flex: 1"><b>{{ $t('scoring') }}</b></p>
															<p class="text-white mb-0" style="flex: 1"><b>{{ $t('trainingLevel') }}</b></p>
														</div>
														<div class="d-flex align-items-center justify-content-center border-bottom"
																v-for="(scoring, index) in referenceScoring" :key="index"
																:class="setClassForScoring(index, evaluationData.evaluacion.scoring)"
																>
															<p class="mb-1 d-flex align-items-center px-2 py-1" style="flex: 1">
																{{scoring.nentrada}}
															</p>
															<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">
																{{scoring.value}}
															</p>
															<p class="mb-1 d-flex align-items-center px-2 py-1" style="flex: 1">
																{{scoring.nentreno}}
															</p>
														</div>
													</div>
												</CCol>
											</CRow>
										</CCol>
										<CCol xs="12" lg="6">
											<h5 class="bg-primary text-white text-center py-2">{{ $t('trainingVolumePercent') }}</h5>
											<div>
												<Doughnut :labels="[$t('warmUp'), $t('areobic'), $t('increment')]"
													:datasets="[{
														backgroundColor: [
															'#41AB35',
															'#3A2151',
															'#CED2D8',
														],
														data: [this.scoringNentrenaRef.n1, this.scoringNentrenaRef.n2, this.scoringNentrenaRef.n3]
													}]"/>
											</div>
										</CCol>
									</CRow>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-list"/> {{ $t('rockportTest') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <h5 class="bg-primary text-white text-center py-2">{{ $t('rockportTest') }}</h5>
                </CCol>
                <CCol xs="12" lg="12" v-if="evaluationData.rockport_data">
                  <div>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('rockportMileTime') }}: </b> {{evaluationData.rockport_data.minutes}}:{{evaluationData.rockport_data.seconds}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1 bg-secondary">
                      <b class="text-primary">{{ $t('BPM') }}: </b> {{evaluationData.rockport_data.bpm}}
                    </p>
                    <p class="mb-1 d-flex align-items-center justify-content-between px-2 py-1">
                      <b class="text-primary">{{ $t('value') }}: </b> {{evaluationData.rockport_data.value}}
                    </p>
                  </div>

									<div class="mt-4" v-if="evaluationData.evaluado.sexo == 'Masculino'">
										<div class="d-flex align-items-center justify-content-center bg-dark px-2 py-1 text-center">
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('male') }}</b></p>
											<p class="text-white justify-content-center mb-0 " style="flex: 1"><b>{{ $t('percentile') }}</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>13–29</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>30–39</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>40–49</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>50–59</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>60–69</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>70–79</b></p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Superior</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">95</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">55.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">54.0</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">52.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">48.9</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">45.7</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">42.1</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Excelente</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">80</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">51.1</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">48.3</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">46.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">43.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">39.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">36.7</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Bueno</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">60</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">45.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">44.0</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">42.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">39.2</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">35.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">32.3</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Aceptable</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">40</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">41.7</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">40.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">38.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">35.6</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">32.3</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">29.4</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Deficiente</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">0-40</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><41.7</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><40.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><38.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><35.6</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><32.3</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><29.4</p>
										</div>
									</div>

									<div class="mt-4" v-else>
										<div class="d-flex align-items-center justify-content-center bg-dark px-2 py-1 text-center">
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>{{ $t('female') }}</b></p>
											<p class="text-white justify-content-center mb-0 " style="flex: 1"><b>{{ $t('percentile') }}</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>13–29</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>30–39</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>40–49</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>50–59</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>60–69</b></p>
											<p class="text-white justify-content-center mb-0" style="flex: 1"><b>70–79</b></p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Superior</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">95</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">49.6</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">47.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">45.3</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">41.1</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">37.8</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">36.7</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Excelente</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">80</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">43.9</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">42.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">39.7</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">36.7</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">33.0</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">30.9</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Bueno</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">60</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">39.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">37.8</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">36.3</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">33.0</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">30.0</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">28.1</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Aceptable</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">40</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">36.1</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">34.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">33.0</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">30.1</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">27.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">25.9</p>
										</div>
										<div class="d-flex align-items-center justify-content-center border-bottom text-center">
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">Deficiente</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1">0-40</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><36.1</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><34.4</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><33.0</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><30.1</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><27.5</p>
											<p class="mb-1 d-flex align-items-center justify-content-center px-2 py-1" style="flex: 1"><25.9</p>
										</div>

									</div>
								</CCol>
                <CCol xs="12" lg="12" v-else>
									No hay información para este item
								</CCol>
							</CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-restaurant"/> {{ $t('nutritionalRequirements') }}
              </template>
              <CRow>
                <CCol xs="12" md="4">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('caloricRequirement') }}</h5>
									<div>
										<div class="d-flex align-items-center justify-content-between p-1">
											<p class="mb-0 text-center"><b>{{ $t('rcaloricoa') }}</b></p>
											<p class="mb-0 text-center">{{evaluationData.evaluacion.rcalorico * 0.9 | twoDecimal}}</p>
										</div>
										<div class="d-flex align-items-center justify-content-between p-1 bg-secondary">
											<p class="mb-0 text-center"><b>{{ $t('rcaloricob') }}</b></p>
											<p class="mb-0 text-center">{{evaluationData.evaluacion.rcalorico | twoDecimal}}</p>
										</div>
										<div class="d-flex align-items-center justify-content-between p-1">
											<p class="mb-0 text-center"><b>{{ $t('rcaloricoc') }}</b></p>
											<p class="mb-0 text-center">{{evaluationData.evaluacion.rcalorico * 1.1 | twoDecimal}}</p>
										</div>
									</div>
									<h5 class="bg-primary text-white text-center py-2 mt-3">
										{{ $t('caloricRequirement') }}
										<CButton color="secondary" size="sm" @click="editingEvaluation = !editingEvaluation"><CIcon name="cil-pencil"/></CButton>
									</h5>
									<div>
										<v-select
											v-if="editingEvaluation"
											:placeholder="$t('selection')"
											:options="nutriOptions"
											:clearable="false"
											required
											@input="changeNutri"
											v-model="nutriSelect"></v-select>
										<div class="d-flex align-items-center justify-content-between p-1">
											<p class="mb-0 text-center"><b>{{ $t('lipidsAndOthers') }}</b></p>
											<CInput
												v-if="editingEvaluation"
												v-model="editEvaluation.lipidos"
												type="number"
												style="max-width: 60px;"
											/>
											<p class="mb-0 text-center" v-else>{{evaluationData.evaluacion.lipidos}}%</p>
										</div>
										<div class="d-flex align-items-center justify-content-between p-1">
											<p class="mb-0 text-center"><b>{{ $t('proteins') }}</b></p>
											<CInput
												v-if="editingEvaluation"
												v-model="editEvaluation.proteinas"
												type="number"
												:placeholder="$t('enterWaistPerimeter')"
												style="max-width: 60px;"
											/>
											<p class="mb-0 text-center" v-else>{{evaluationData.evaluacion.proteinas}}%</p>
										</div>
										<div class="d-flex align-items-center justify-content-between p-1 bg-secondary">
											<p class="mb-0 text-center"><b>{{ $t('carbohydrates') }}</b></p>
											<CInput
												v-if="editingEvaluation"
												v-model="editEvaluation.carbohidratos"
												type="number"
												style="max-width: 60px;"
											/>
											<p class="mb-0 text-center" v-else>{{evaluationData.evaluacion.carbohidratos}}%</p>
										</div>
										<CButton v-if="editingEvaluation" color="primary" block @click="updateEvaluation" :disabled="loadingEdit" size="sm"><CIcon name="cil-check-circle"/> {{$t('update')}}</CButton>
									</div>
									<div class="mt-1">
										<p class="mb-0">Tener ingestas cada 200 minutos (aproximadamente).</p>
										<p class="mb-0">Consumir un promedio de 2 litros de agua.</p>
									</div>
                </CCol>
								<CCol xs="12" md="8">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('macronutrients') }}</h5>
									<div>
										<Doughnut :labels="[$t('proteins'), $t('carbohydrates'), $t('lipidsAndOthers')]"
											:datasets="[{
												backgroundColor: [
													'#41AB35',
													'#3A2151',
													'#CED2D8',
												],
												data: [evaluationData.evaluacion.proteinas, evaluationData.evaluacion.carbohidratos, evaluationData.evaluacion.lipidos]
											}]"/>
									</div>
								</CCol>
              </CRow>
              <CRow class="mt-5">
                <CCol xs="12" md="6">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('nutritionalTraffic') }}</h5>
									<div class="px-3">
										<div class="d-flex align-items-center justify-content-start p-1">
											<CIcon name="cil-x-circle" size="2xl" class="mr-3" style="color: #E85757"/>
											<h5 class="mb-0">{{$t('semaforoTitleA')}}</h5>
										</div>
										<div class="my-3">
											{{$t('semaforoTextA')}}
										</div>
										<div class="d-flex align-items-center justify-content-start p-1">
											<CIcon name="cil-check-circle" size="2xl" class="mr-3" style="color: #90B902"/>
											<h5 class="mb-0">{{$t('semaforoTitleB')}}</h5>
										</div>
										<div class="my-3">
											{{$t('semaforoTextB')}}
										</div>
									</div>
                </CCol>
                <CCol xs="12" md="6">
									<h5 class="bg-primary text-white text-center py-2">{{ $t('macronutrients') }}</h5>
									<div class="px-3">
										<div class="my-3">
											<b>{{$t('proteins')}}: </b> {{$t('proteinsExplainText')}}
										</div>
										<div class="my-3">
											<b>{{$t('carbohydrates')}}: </b> {{$t('carbohydratesExplainText')}}
										</div>
										<div class="my-3">
											<b>{{$t('lipidsAndOthers')}}: </b> {{$t('lipidsAndOthersExplainText')}}
										</div>
									</div>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-history"/> {{ $t('historic') }}
              </template>
              <CRow class="no-gutters">
                <CCol sm="4">
									<div>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-primary text-white">{{ $t('date') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('imc') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('imb') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('fatPercent') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('muscularPercent') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('residualPercent') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('fatWeight') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('muscularWeight') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('residualWeight') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('visceralFat') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('weight') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('smoke') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('alcohol') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('waistPerimeter') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold bg-secondary">{{ $t('scoring') }}</p>
										<p style="font-size: 10px;line-height: 21px;" class="mb-0 px-2 py-1 font-weight-bold">{{ $t('entryLevel') }}</p>
									</div>
                </CCol>
                <CCol sm="8">
									<CRow class="no-gutters">
										<CCol sm="4" v-for="(evaluation, index) in evaluationData.history" :key="index">
											<div class="text-right">
												<p class="mb-0 px-2 py-1 font-weight-bold bg-primary text-white"><small>{{evaluation.created_at}}</small></p>
												<p class="mb-0 px-2 py-1">{{evaluation.imc | twoDecimal}}</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{evaluation.imb | twoDecimal}} {{ $t('calories') }}</p>
												<p class="mb-0 px-2 py-1">{{evaluation.pgrasa | twoDecimal}}%</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{parseFloat(evaluation.masamuscular) | twoDecimal}}%</p>
												<p class="mb-0 px-2 py-1">{{parseFloat(evaluation.pmresidual) | twoDecimal}}%</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{evaluation.pgraso | twoDecimal}} Kg.</p>
												<p class="mb-0 px-2 py-1">{{parseFloat(evaluation.pmuscular) | twoDecimal}} Kg.</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{parseFloat(evaluation.mresidual) | twoDecimal}} Kg.</p>
												<p class="mb-0 px-2 py-1">{{evaluation.rango_grasa_viceral}}</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{evaluation.peso}} Kg.</p>
												<p class="mb-0 px-2 py-1">{{evaluation.fuma}}</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{evaluation.alcohol}}</p>
												<p class="mb-0 px-2 py-1">{{evaluation.pcintura}} cm.</p>
												<p class="mb-0 px-2 py-1 bg-secondary">{{evaluation.scoring | twoDecimal}}</p>
												<div class="mb-0 px-2 py-1 d-flex align-items-center justify-content-end">
													{{evaluation.nentrada}}
													<div class="history-nentrena ml-2" :class="setClassForScoringValue(evaluation.scoring)"></div>
												</div>
											</div>
										</CCol>
									</CRow>
                </CCol>
              </CRow>
            </CTab>
            <CTab>
              <template slot="title">
                <CIcon name="cil-envelope-open"/> {{ $t('sendEvaluation') }}
              </template>
              <CRow>
                <CCol xs="12" lg="12">
                  <CRow>
                    <CCol xs="12" lg="12">
                      <h5 class="bg-primary text-white text-center py-2"> {{ $t('sendEvaluationByEmail') }}</h5>
                      <div>
                        <CInput
                          v-model="sendevaluationEmail"
                          :label="$t('email')"
                          :placeholder="$t('enterEmail')"
                          type="email"
                          horizontal
                          :isValid="needValidation ? required && email:undefined"
                        />
                        <CButton type="submit" color="primary" block @click="sendEvaluation" :disabled="loading"><CIcon name="cil-check-circle" /> {{ $t('sendEvaluation') }}</CButton>
                      </div>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CTab>
          </CTabs>
        </CCardBody>
      </CCard>
    </div>
</template>

<script>
import moment from 'moment'
import {getOrCreateUserChatRoom} from '@/helpers/api/user'
import {getEvaluation, sendEvaluationByEmail, editEvaluation} from '@/helpers/api/evaluations.js'
import Doughnut from '@/views/chart/Doughnut'
import {required, email} from '@/helpers/validators'

export default {
  name: 'EvaluationDetail',
  components: {Doughnut},
  filters: {
    age: function (birth) {
      return moment().diff(moment(birth, 'YYYY-MM-DD'), 'years')
    },
    monthsToAge: function (months){
      const years = parseInt( months/12 )
      const ymonths = parseInt( months%12 )
      return years + ' años ' + ymonths + ' meses'
    },
    twoDecimal: function (number){
      return (Math.round(number * 100)/100).toFixed(2)
    }
  },
  data () {
    return {
      needValidation: false,
			loadingNewChat: false,
			selTime: 40,
      waistHeight: [this.$i18n.t('severeThinness'),this.$i18n.t('slightThinness'),this.$i18n.t('normalWeight'),this.$i18n.t('overweight'),this.$i18n.t('highOverweight'),this.$i18n.t('morbidObesity')],
      referencePGrasa: [
        {value: this.$i18n.t('veryLow'), reference: '< 5', calcValue: 5},
        {value: this.$i18n.t('low'), reference: '5 - 10 ', calcValue: 10},
        {value: this.$i18n.t('optimum'), reference: '11 - 15', calcValue: 15},
        {value: this.$i18n.t('moderate'), reference: '16 - 20', calcValue: 20},
        {value: this.$i18n.t('highModerate'), reference: '21 - 25', calcValue: 25},
        {value: this.$i18n.t('high'), reference: '26 - 30', calcValue: 30},
        {value: this.$i18n.t('veryHigh'), reference: '31 - 35 ', calcValue: 35},
        {value: this.$i18n.t('extremelyHigh'), reference: '35 <', calcValue: 999999}
      ],
      referenceScoring: [
        {nentrada: this.$i18n.t('highRisk'), value: '3.6 - 7', calcValue: 7, nentreno: this.$i18n.t('basic')},
        {nentrada: this.$i18n.t('risk'), value: '2.6 - 3.5', calcValue: 3.5, nentreno: this.$i18n.t('basic')},
        {nentrada: this.$i18n.t('sedentary'), value: '1.1 - 2.5', calcValue: 2.5, nentreno: this.$i18n.t('basic')},
        {nentrada: this.$i18n.t('normal') + ' ' + this.$i18n.t('sedentary'), value: '0.3 - 1', calcValue: 1, nentreno: this.$i18n.t('medium')},
        {nentrada: this.$i18n.t('normal'), value: '-0.5 - 0.2', calcValue: 0.2, nentreno: this.$i18n.t('medium')},
        {nentrada: this.$i18n.t('fit'), value: '-1.5 - -0.6', calcValue: -0.6, nentreno: this.$i18n.t('medium')},
        {nentrada: this.$i18n.t('sports'), value: '-2.9 - -1.6', calcValue: -1.6, nentreno: this.$i18n.t('advance')},
        {nentrada: this.$i18n.t('athletic'), value: '-3.9 - -3', calcValue: -3, nentreno: this.$i18n.t('advance')},
        {nentrada: this.$i18n.t('elite'), value: '-5 - -4', calcValue: -4, nentreno: this.$i18n.t('advance')},
      ],
			timeOptions: [
				{label: '40 ' + this.$i18n.t('minutes'), value: 40},
				{label: '50 ' + this.$i18n.t('minutes'), value: 50},
				{label: '60 ' + this.$i18n.t('minutes'), value: 60},
				{label: '70 ' + this.$i18n.t('minutes'), value: 70},
				{label: '80 ' + this.$i18n.t('minutes'), value: 80},
				{label: '90 ' + this.$i18n.t('minutes'), value: 90}
			],
			nutriSelect: null,
			nutriOptions: [
				{label: this.$i18n.t('mantainVolume'), value: [40,30,30]},
				{label: this.$i18n.t('reduceVolume'), value: [40,40,20]},
				{label: this.$i18n.t('addVolume'), value: [30,30,40]}
			],
      // referenceScoring: [
      //   {nentrada: 'Alto Riesgo', value: '3.6 - 7', calcValue: 7, nentreno: 'Basico I'},
      //   {nentrada: 'Riesgo', value: '2.6 - 3.5', calcValue: 3.5, nentreno: 'Basico II'},
      //   {nentrada: 'Sedentario', value: '1.1 - 2.5', calcValue: 2.5  , nentreno: 'Basico III'},
      //   {nentrada: 'Normal Sedentario', value: '0.3 - 1', calcValue:  1, nentreno: 'Intermedio I'},
      //   {nentrada: 'Normal ', value: '-0.5 - 0.2', calcValue: 0.2, nentreno: 'Intermedio II'},
      //   {nentrada: 'En Forma', value: '-1.5 - -0.6', calcValue: -0.6, nentreno: 'Intermedio III'},
      //   {nentrada: 'Atlético', value: '-2.9 - -1.6', calcValue: -1.6, nentreno: 'Avanzado I'},
      //   {nentrada: 'Deportivo', value: '-3.9 - -3', calcValue: -3, nentreno: 'AvanzadoII'},
      //   {nentrada: 'Elite', value: '-8 - -4', calcValue: -4, nentreno: 'Avanzado III'},
      // ],
      loadingEvaluation: false,
      evaluationData: null,
      sendevaluationEmail: '',
      loading: false,
			scoringNentrenaRef: {
				n1: 10,
				n2: 80,
				n3: 10,
			},
			editEvaluation: {
				lipidos: 0,
				proteinas: 0,
				carbohidratos: 0
			},
			editingEvaluation: false,
			loadingEdit: false
    }
  },
  mounted(){
    this.getEvaluation()
  },
  methods: {
    required, email,
		setNutri(){
			if(this.evaluationData.evaluacion && this.evaluationData.evaluacion.lipidos && this.evaluationData.evaluacion.proteinas && this.evaluationData.evaluacion.carbohidratos){
				const actualNutri = [
					parseInt(this.evaluationData.evaluacion.lipidos),
					parseInt(this.evaluationData.evaluacion.proteinas),
					parseInt(this.evaluationData.evaluacion.carbohidratos)
				]
				console.log('actualNutri', actualNutri)
				this.nutriOptions.forEach((option) => {
					// Extrae los valores reales del array para evitar la propiedad __ob__
					const optionValues = option.value.slice(); // Clonamos el array para evitar la reactividad
					console.log(optionValues);

					if (JSON.stringify(optionValues) === JSON.stringify(actualNutri)) {
						this.nutriSelect = option;
					}
				})
			}
		},
		changeNutri(nutri){
			//[lip, prot, carb]
			this.editEvaluation = {
				lipidos: nutri.value[0],
				proteinas: nutri.value[1],
				carbohidratos: nutri.value[2]
			}
			console.log('this.editEvaluation: ', this.editEvaluation)
		},
		async chatWithUser(){
			this.loadingNewChat = true
			const datos = {
				user_id: this.$store.state.userData.id_usuario,
				called_user_id: this.evaluationData.evaluado.id_usuario,
			}
			const room = await getOrCreateUserChatRoom(datos)
			this.$store.dispatch('getUserChatRoomsList')
			this.$store.commit('setModalChatStatus', true)
			this.$store.commit('setSelectedRoom', room.user_room)
			this.loadingNewChat = false
		},
		setEditInfo(evaluationData){
			this.editEvaluation = {
				lipidos: evaluationData.evaluacion.lipidos,
				proteinas: evaluationData.evaluacion.proteinas,
				carbohidratos: evaluationData.evaluacion.carbohidratos
			}
		},
		async updateEvaluation(){
			const sum = parseInt(this.editEvaluation.carbohidratos) + parseInt(this.editEvaluation.lipidos) + parseInt(this.editEvaluation.proteinas)
			if(sum != 100){
				this.$store.commit('addToast', {
					color: 'warning',
					header: 'Error',
					message: 'La suma de los nutrientes debe ser igual a 100%.'
				})
				return null
			}
			const data = {
				id_evaluacion: this.evaluationData.evaluacion.id,
				...this.editEvaluation
			}
			this.loadingEdit = true
			await editEvaluation(data)
			this.loadingEdit = false
    	this.getEvaluation()
		},
		setPercentEntrena(){
			if(this.evaluationData.evaluacion.nentrena == "Básico"){
				this.scoringNentrenaRef = {
					n1: 10,
					n2: 80,
					n3: 10,
				}
			}
			else if(this.evaluationData.evaluacion.nentrena == "Intermedio"){
				this.scoringNentrenaRef = {
					n1: 10,
					n2: 60,
					n3: 30,
				}
			}
			else if(this.evaluationData.evaluacion.nentrena == "Avanzado"){
				this.scoringNentrenaRef = {
					n1: 10,
					n2: 45,
					n3: 45,
				}
			}
		},
		setClassForScoringValue(value){
			if(value > 3.5){
				return 'bgcolorrangoa'
			}
			else if(value > 2.5){
				return 'bgcolorrangob'
			}
			else if(value > 1){
				return 'bgcolorrangoc'
			}
			else if(value > 0.2){
				return 'bgcolorrangod'
			}
			else if(value > -1.6){
				return 'bgcolorrangoe'
			}
			else{
				return 'bgcolorrangof'
			}
		},
    setClassForScoring(index, value){
      const referenceScoring = [...this.referenceScoring]
      const found = referenceScoring.reverse().findIndex((item) => {
        return item.calcValue > value
      })
      if(index == referenceScoring.length - 1 - found){
        if(index == 0){
          return 'bgcolorrangoa'
        }
        else if(index == 1){
          return 'bgcolorrangob'
        }
        else if(index == 2){
          return 'bgcolorrangoc'
        }
        else if(index == 3){
          return 'bgcolorrangod'
        }
        else if(index < 6){
          return 'bgcolorrangoe'
        }
        else{
          return 'bgcolorrangof'
        }
      }
    },
    setClassForPGrasa(index, value){
      let classname = ''
      if(index%2 == 0){ classname = ' bg-secondary' }
      const found = this.referencePGrasa.findIndex((item) => {
        return value <= item.calcValue
      })
      if(found == index){
        if(index == 1 || index == 3 || index == 4){
          classname = ' bg-warning'
        }
        else if(index != 2){
          classname = ' bg-danger'
        }
        else{
          classname = ' bg-success'
        }
      }
      return classname
    },
    setClassForWaisHeight(index, value){
      let classname = ''
      if(index%2 == 0){ classname = ' bg-secondary' }
      if(index == value){
        if(index == 1 || index == 3){
          classname = ' bg-warning'
        }
        else if(index == 0 || index == 4 || index == 5){
          classname = ' bg-danger'
        }
        else{
          classname = ' bg-success'
        }
      }
      return classname
    },
    async getEvaluation(){
      if(this.$route.params.evaluationID){
        this.loadingEvaluation = true
        const id = this.$route.params.evaluationID
        const evaluation = await getEvaluation({id})
        if(evaluation.evaluacion){
          this.evaluationData = evaluation
					this.sendevaluationEmail = evaluation.evaluado.email
					this.setPercentEntrena()
					this.setEditInfo(this.evaluationData)
					this.setNutri()
        }
        this.loadingEvaluation = false
      }
      else{
        this.$router.push({ name: 'Evaluaciones' })
      }
    },
    async sendEvaluation(){
      this.needValidation = true
      if(this.email(this.sendevaluationEmail)){
        this.loading = true
        try{
          const data = {
            email: this.sendevaluationEmail,
            id: this.$route.params.evaluationID,
            lang: localStorage.getItem('lang'),
          }
          const sent = await sendEvaluationByEmail(data)
          this.$store.commit('addToast', {
            color: 'success',
            header: 'Evaluación enviada',
            message: 'Se ha enviado la evaluación al correo ingresado.'
          })
        }
        catch(e){
          this.$store.commit('addToast', {
            color: 'warning',
            header: 'Error',
            message: 'Hubo un error el enviar la evaluación, por favor intenta nuevamente.'
          })
        }
        this.loading = false
      }
      else{
        this.$store.commit('addToast', {
          color: 'warning',
          header: 'Error',
          message: 'Por favor verifica el correo ingresado.'
        })
      }
    }
  }
}
</script>
<style scoped>
	p{
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.color-indicator{
		width: 20px;
		height: 20px;;
	}
	.bgcolorrangoa {
    background: #F44336!important;
    color: white!important;
	}
	.bgcolorrangob {
    background: #ED7D33!important;
    color: white!important;
	}
	.bgcolorrangoc {
    background: #FF0!important;
    color: white!important;
	}
	.bgcolorrangod {
    background: #00B052!important;
    color: white!important;
	}
	.bgcolorrangoe {
    background: #3074B3!important;
    color: white!important;
	}
	.bgcolorrangof {
    background: #012061!important;
    color: white!important;
	}
	.history-nentrena{
		width: 10px;
		height: 10px;
		border-radius: 10px;
	}
</style>